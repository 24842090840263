import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Noleggio di yacht Kateryna
			</title>
			<meta name={"description"} content={"La nostra promessa è una miscela perfetta di comfort e scoperta, creata per coloro che cercano di abbandonarsi alla bellezza della distesa dell'oceano. "} />
			<meta property={"og:title"} content={"Noleggio di yacht Kateryna"} />
			<meta property={"og:description"} content={"La nostra promessa è una miscela perfetta di comfort e scoperta, creata per coloro che cercano di abbandonarsi alla bellezza della distesa dell'oceano. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6606aeb30072400020ebce37"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});